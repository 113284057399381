import React from 'react';

import { Container } from '../../styles/GlobalStyles';
import { HomeContainer } from './styled';

export default function Main() {
   return (
      <Container>
         <HomeContainer>
            <h3>MAIN Page</h3>
         </HomeContainer>
      </Container>
   );
}
