import React from 'react';

import { Container } from '../../styles/GlobalStyles';
import { HomeContainer } from './styled';

export default function Home() {
   return (
      <Container>
         <HomeContainer>
            <h3>Seja bem vindo!</h3>
            <p>
               Este sistema foi desenvolvido para controle de movimento de
               estoque e consulta de disponibilidade de itens, garantindo aos
               colaboradores uma pesquisa mais rápida e eficiente quanto aos
               produtos disponíveis para venda.
            </p>
            <h3> Selecione uma das opções no menu.</h3>
         </HomeContainer>
      </Container>
   );
}
