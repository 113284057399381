import React from 'react';

import { Container } from '../../styles/GlobalStyles';
import { Title, Paragrafo } from './styled';

export default function SearchStock() {
   return (
      <Container>
         <Title>
            Essa é a tela de{' '}
            <small>Pesquisa de disponibilidade de estoque.</small>
         </Title>
         <Paragrafo>Lorem Ipsum</Paragrafo>
         <button type="button"> Enviar</button>
      </Container>
   );
}
