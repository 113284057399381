import React from 'react';
import { FaCircle, FaUser } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { UserPhoto, UserNavContainer } from './UserInfoStyled';

export default function UserInfo({ user }) {
   return (
      <UserNavContainer>
         {user && <FaCircle size={12} color="#5ecc69" title="Online" />}
         <UserPhoto>
            <FaUser size={16} color="fff" />
         </UserPhoto>
         <div>
            <p>Usuário: {user.nome}</p>
            <p>ID: {user.id}</p>
         </div>
      </UserNavContainer>
   );
}

UserInfo.propTypes = {
   user: PropTypes.object,
};
