import React, { useState } from 'react';
import { Container } from '../../styles/GlobalStyles';
import { CategoryForm } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { get } from 'lodash';

export default function NewProdCategory() {
   const [isLoading, setIsLoading] = useState(false);
   const [newCat, setNewCat] = useState('');

   const handleAddCategory = async (e) => {
      e.preventDefault();
      try {
         setIsLoading(true);
         const cat = {
            name: newCat,
         };
         await axios.post('/prodcategories', cat);
         toast.success(`Categoria "${newCat}" foi cadastrada com sucesso.`);
         handleClearFields();
         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         toast.error(errors);
         setIsLoading(false);
      }
   };

   const handleClearFields = () => {
      setNewCat('');
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <h3>Cadastre uma nova categoria</h3>
         <CategoryForm>
            <label>Nome da categoria:</label>
            <input
               type="text"
               value={newCat}
               onChange={(e) => setNewCat(e.target.value)}
            ></input>
            <button onClick={handleAddCategory}>Cadastrar</button>
         </CategoryForm>
      </Container>
   );
}
