/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';

import { Container } from '../../styles/GlobalStyles';

import { Form, Title, Paragrafo, SearchUserBar } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function EditUser() {
   const dispatch = useDispatch();
   const id = useSelector((state) => state.auth.user.id);
   const nomeStored = useSelector((state) => state.auth.user.nome);
   const emailStored = useSelector((state) => state.auth.user.email);
   const isLoading = useSelector((state) => state.auth.isLoading);

   const [nome, setNome] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');

   React.useEffect(() => {
      if (!id) return;
      setNome(nomeStored);
      setEmail(emailStored);
   }, [id, nomeStored, emailStored]);

   async function handleSubmit(e) {
      e.preventDefault();

      let formErrors = false;

      if (nome.length < 3 || nome.length > 255) {
         formErrors = true;
         toast.error('O campo nome precisa ter entre 3 e 255 caracteres.');
      }

      if (!isEmail(email)) {
         formErrors = true;
         toast.error('O email precisa ser válido.');
      }

      if (!id && (password.length < 6 || password.length > 50)) {
         formErrors = true;
         toast.error('O campo senha precisa ter entre 6 e 50 caracteres.');
      }

      if (formErrors) return;

      dispatch(actions.registerRequest({ nome, email, password, id }));
   }

   return (
      <Container>
         <Loading isLoading={isLoading} />

         <Title>Tela: Editar Usuário</Title>
         <SearchUserBar>
            <div>
               <p>Digite o email do usuário:</p>
            </div>
            <div>
               <input placeholder="user@email.com"></input>
            </div>
            <div>
               <button type="submit">Pesquisar</button>
            </div>
         </SearchUserBar>
         <Form onSubmit={handleSubmit}>
            <div>
               <label htmlFor="nome">Nome:</label>
               <input
                  type="text"
                  id="nome"
                  value={nome}
                  placeholder="Insira o nome do usuário"
                  onChange={(e) => setNome(e.target.value)}
               />
            </div>

            <div>
               <label htmlFor="email">Email:</label>
               <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder="Digite um email válido"
                  onChange={(e) => setEmail(e.target.value)}
               />
            </div>

            <div>
               <label htmlFor="password">Senha:</label>
               <input
                  type="password"
                  id="password"
                  value={password}
                  placeholder="Digite a senha para o usuário"
                  onChange={(e) => setPassword(e.target.value)}
               />
            </div>

            <button type="submit">Atualizar</button>
         </Form>
         <Paragrafo>
            MyShop Web App | 2024-2024 - Todos os direitos reservados.
         </Paragrafo>
      </Container>
   );
}
