import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   formatDecimalToCurrency,
   parseCurrencyToDecimal,
} from '../../utils/formatCurrency';

const CurrencyInput = ({ value, onChange, disabled }) => {
   // Limitando o número de casas decimais a duas
   const handleChange = (e) => {
      const inputValue = e.target.value;

      // Remove qualquer caractere que não seja dígito, incluindo o ponto.
      const rawValue = inputValue.replace(/[^\d]/g, '');

      // Se o valor não for vazio, converte para decimal.
      const decimalValue = rawValue ? parseFloat(rawValue) / 100 : 0;

      // Formata o valor para moeda.
      onChange(
         decimalValue > 0 ? formatDecimalToCurrency(decimalValue) : 'R$ 0,00'
      );
   };

   return (
      <input
         className="currency-field"
         type="text"
         value={formatDecimalToCurrency(value)} // Exibe o valor formatado
         onChange={handleChange}
         disabled={disabled}
      />
   );
};

CurrencyInput.propTypes = {
   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Pode aceitar string ou number
   onChange: PropTypes.func.isRequired, // Validação da função de callback
   disabled: PropTypes.bool.isRequired,
};

export default CurrencyInput;
