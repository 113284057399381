import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../../components/Loading';
import {
   Container,
   TableData,
   TableHeader,
   TableFooter,
   TrEnabledAction,
} from '../../styles/GlobalStyles';
import { Title, Paragrafo, Pagination } from './styled';
import { QueryContainer } from '../../styles/QueryTables';
import axios from '../../services/axios';
import { toast } from 'react-toastify';

export default function Products() {
   const [categories, setCategories] = useState([]);
   const [brands, setBrands] = useState([]);

   const [prodId, setProdId] = useState('');
   const [prodSku, setProdSku] = useState('');
   const [prodName, setProdName] = useState('');
   const [prodCategory, setProdCategory] = useState('');
   const [prodBrand, setProdBrand] = useState('');
   const [minStock, setMinStock] = useState('');

   const [products, setProducts] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 15;

   useEffect(() => {
      async function getCategories() {
         try {
            setIsLoading(true);
            const categories = await axios.get('/prodcategories');
            const brands = await axios.get('/prodbrands');
            setCategories(categories.data);
            setBrands(brands.data);
            setIsLoading(false);
         } catch (err) {
            const [error] = err.response.data.errors;
            console.log(err);
            toast.error(error);
            if (err.response.status === 401) {
               toast.warn(
                  `${err.response.status} - Não autorizado, refaça seu login.`
               );
            }
            console.log(error);
            setIsLoading(false);
         }
      }
      getCategories();
   }, []);

   const handleGetProducts = async (e) => {
      e.preventDefault();
      setIsLoading(true);

      const query = {
         id: prodId,
         prod_sku: prodSku,
         prod_name: prodName,
         category_id: prodCategory,
         brand_id: prodBrand,
         below_min_stock: minStock,
      };
      const response = await axios.post('/product-search', query);
      setProducts(response.data);
      setIsLoading(false);
   };

   // Calcula o índice do primeiro e do último item a ser exibido na página atual
   const indexOfLastProduct = currentPage * itemsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

   // Cria uma lista de produtos para exibir na página atual
   const currentProducts = products.slice(
      indexOfFirstProduct,
      indexOfLastProduct
   );

   // Calcula o número total de páginas
   const totalPages = Math.ceil(products.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   // Lógica para renderizar somente 5 itens por vez na paginação
   const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5;
      const halfRange = Math.floor(maxPageNumbersToShow / 2);

      let startPage = Math.max(1, currentPage - halfRange);
      let endPage = Math.min(totalPages, currentPage + halfRange);

      if (currentPage <= halfRange) {
         endPage = Math.min(totalPages, maxPageNumbersToShow);
      }

      if (currentPage + halfRange >= totalPages) {
         startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
         pageNumbers.push(
            <button
               key={i}
               onClick={() => handlePageChange(i)}
               className={currentPage === i ? 'active' : null}
            >
               {i}
            </button>
         );
      }

      return pageNumbers;
   };

   const navigate = useNavigate();

   const handleRowClick = (id) => {
      navigate(`/products/${id}`);
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>Lista de produtos</Title>
         <Paragrafo>Edição de nome e visualização de estoque</Paragrafo>
         <QueryContainer>
            <form onSubmit={handleGetProducts}>
               <div className="input-fields">
                  <div>
                     <label>ID do produto:</label>
                     <input
                        type="text"
                        value={prodId}
                        onChange={(e) => setProdId(e.target.value)}
                     ></input>
                  </div>
                  <div>
                     <label>SKU:</label>
                     <input
                        value={prodSku}
                        type="text"
                        onChange={(e) => setProdSku(e.target.value)}
                     ></input>
                  </div>
                  <div className="input-large">
                     <label>Nome do produto</label>
                     <input
                        value={prodName}
                        type="text"
                        onChange={(e) => setProdName(e.target.value)}
                     ></input>
                  </div>
                  <div>
                     <label>Categoria</label>
                     <select
                        value={prodCategory}
                        onChange={(e) => setProdCategory(e.target.value)}
                     >
                        <option value="">Selecione uma categoria</option>
                        {categories.map((category) => (
                           <option
                              key={String(category.id)}
                              value={category.id}
                           >
                              {category.name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div>
                     <label>Marca</label>
                     <select
                        value={prodBrand}
                        onChange={(e) => setProdBrand(e.target.value)}
                     >
                        <option value="">Selecione a marca</option>
                        {brands.map((brand) => (
                           <option key={String(brand.id)} value={brand.id}>
                              {brand.name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div>
                     <label>Estoque</label>
                     <select
                        value={minStock}
                        onChange={(e) => setMinStock(e.target.value)}
                     >
                        <option value="">Sem filtro de estoque</option>
                        <option value={true}>Abaixo do estoque mínimo</option>
                     </select>
                  </div>
               </div>
               <button type="submit">Pesquisar</button>
            </form>
         </QueryContainer>
         <TableHeader />
         <TableData>
            <table>
               <thead>
                  <tr>
                     <th>ID</th>
                     <th>SKU</th>
                     <th className="th-extralarge-w">Nome produto</th>
                     <th>Categoria</th>
                     <th>Marca</th>
                     <th>Qtd.</th>
                     <th>Estoque mín.</th>
                  </tr>
               </thead>
               <tbody>
                  {currentProducts.map((product) => (
                     <TrEnabledAction
                        key={String(product.id)}
                        onDoubleClick={() => handleRowClick(product.id)}
                     >
                        <td className="td-small-w">{product.id}</td>
                        <td className="td-small-w">{product.prod_sku}</td>
                        <td title={product.prod_name}>{product.prod_name}</td>
                        <td className="td-medium-w">{product.category_name}</td>
                        <td className="td-medium-w">{product.brand_name}</td>
                        <td className="td-small-w">
                           {product.total_stock_qtd === null
                              ? '0'
                              : product.total_stock_qtd}
                        </td>
                        <td className="td-small-w">{product.min_stock_qtd}</td>
                     </TrEnabledAction>
                  ))}
               </tbody>
            </table>
         </TableData>
         <TableFooter />
         <Pagination>
            <button
               onClick={() => handlePageChange(currentPage - 1)}
               disabled={currentPage === 1}
            >
               Anterior
            </button>
            {renderPageNumbers()}
            <button
               onClick={() => handlePageChange(currentPage + 1)}
               disabled={currentPage === totalPages}
            >
               Próximo
            </button>
         </Pagination>
         {/* Adicionado o parágrafo abaixo para exibir o total de páginas */}
         <p>Total de páginas: {totalPages}</p>
         <p>Total de produtos: {products.length}</p>
      </Container>
   );
}
