import styled from 'styled-components';
import * as colors from '../../config/colors';
import logoImg from '../../assets/logo-myshop.png';

export const Nav = styled.nav`
   position: fixed;
   top: 0;
   left: 0;
   background-color: white;
   justify-content: space-between;
   height: 100vh;
   min-height: 100%;
   min-width: 10vw;
   padding: 0 20px;
   display: flex;
   flex-direction: column;

   .nav-content {
      overflow-y: auto;
      flex: 1; /* Isso permite que a div interna cresça e ocupe o espaço disponível */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   h3 {
      margin-bottom: 16px;
      font-weight: lighter;
      font-size: 0.8em;
      color: #fff;
      background-color: ${colors.primaryDarkColor};
      padding: 8px;
      border-radius: 4px;
   }

   a {
      padding: 8px;
      color: ${colors.primaryDarkColor};
      font-weight: lighter;
      align-items: center;
      vertical-align: text-top;
      display: flex;
      column-gap: 16px;
   }
   a:hover {
      background-color: #ddd;
   }

   p {
      color: ${colors.primaryDarkColor};
   }
   .menu-items {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      align-self: flex-start;
   }
`;

export const LogoNav = styled.div`
   display: flex;
   flex-direction: column;
   text-align: center;
   align-self: center;
   background-image: url(${logoImg});
   background-size: contain;
   background-repeat: no-repeat;
   margin-top: 24px;
   width: 80%;
   height: 80px;
   min-height: 24px;
   border-radius: 4px;
`;
