import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: green;
`;

export const MustHave = styled.span`
   color: red;
   font-weight: 800;
`;

export const FormMovement = styled.form`
   width: 100%;
   margin: 24px 0;
   display: flex;
   flex-direction: column;
   row-gap: 8px;

   input {
      height: 36px;
      font-weight: bold;
      color: ${colors.primaryDarkColor};
      padding: 0 0 0 4px;
   }

   button {
      height: 36px;
   }

   .param-field {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      margin-bottom: 16px;
      width: 15%;
   }

   .form-container-h {
      display: flex;
      justify-content: space-between;
      align-items: end;
   }

   input:read-only {
      background-color: #ddd;
      border-radius: 4px;
      border: 1px solid gray;
   }

   .small-p {
      font-size: 0.8em;
   }

   .flag-confirmed {
      color: ${colors.sucessColor};
   }

   .flag-excluded {
      color: ${colors.lightBgText};
   }
`;

export const ProductForm = styled.div`
   display: flex;
   flex-direction: column;
   .form-container-h {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
   }
   .form-container-h div {
      display: flex;
      flex-direction: column;
      width: 100%;
   }

   button {
      margin-top: 24px;
   }
`;

export const ProductsList = styled.table`
   width: 100%;
   border-collapse: collapse;
   margin-bottom: 24px;

   th {
      background-color: ${colors.primaryDarkColor};
      color: #fff;
      height: 48px;
   }

   td {
      padding: 4px 16px 4px 8px;
   }

   td,
   th {
      border: 1px solid #ddd;
      min-width: 48px;
   }

   th {
      background-color: ${colors.primaryDarkColor};
      color: #fff;
      text-align: center;
      height: 48px;
   }
`;
