import styled from 'styled-components';
import loginImg from '../../assets/login-img.jpeg';

export const Title = styled.h1`
   text-align: center;
   margin-bottom: 48px;
`;

export const LoginImg = styled.div`
   background-image: url(${loginImg});
   background-size: contain;
   background-repeat: no-repeat;
   width: 420px;
   height: 540px;
   border-radius: 4px;
`;

export const Paragrafo = styled.p`
   color: gray;
   text-align: center;
`;

export const LoginFlex = styled.div`
   margin-top: 24px;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   background-color: white;
   margin-bottom: 32px;
`;

export const Form = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 8px;
   margin: 24px 0;
   align-items: center;
   width: 30%;

   div {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      column-gap: 16px;
      justify-content: space-between;
      align-items: start;
   }

   input {
      height: 32px;
      width: 100%;
      border: 1px solid #aaa;
      border-radius: 4px;
      padding-left: 8px;
      margin-bottom: 16px;
   }

   label {
   }

   h3 {
      text-align: center;
   }

   button {
      margin-top: 16px;
      width: 100%;
      padding: 8px 0;
      display: block;
      align-self: flex-start;
   }
`;
