/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from '../../styles/GlobalStyles';
import { Title, FormMovement, ProductsList } from './styled';
import Loading from '../../components/Loading';
import axios from '../../services/axios';

export default function Movement() {
   const [movement, setMovement] = useState(null); // Inicializa com null
   const [isLoading, setIsLoading] = useState(false);
   const [productDetails, setProductDetails] = useState([]);

   const { id } = useParams();

   useEffect(() => {
      const fetchMovementData = async () => {
         setIsLoading(true);
         try {
            const { data: movementData } = await axios.get(`/movements/${id}`);
            setMovement(movementData);

            // Verifica se ItemMovement existe antes de tentar mapear
            if (movementData.ItemMovements) {
               // Fetch details for each product
               const productPromises = movementData.ItemMovements.map((item) =>
                  axios.get(`/products/${item.prod_id}`)
               );

               const productResponses = await Promise.all(productPromises);
               const products = productResponses.map((res) => res.data);
               setProductDetails(products);
            }
         } catch (error) {
            console.error('Error fetching movement data', error);
         } finally {
            setIsLoading(false);
         }
      };

      fetchMovementData();
   }, [id]);

   const navigate = useNavigate();
   const handleDeleteMovement = async () => {
      const response = window.confirm(
         'Tem certeza que quer excluir o movimento?'
      );
      if (response) {
         await axios.delete(`/movements/${id}`);
         navigate('/movements');
      }
   };

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>Visualização de movimento</Title>
         {movement && (
            <>
               <FormMovement>
                  <h3>Dados do movimento:</h3>
                  <div className="form-container-h">
                     <div className="param-field">
                        <label>Categoria:</label>
                        <input
                           type="text"
                           value={
                              movement.move_type == 'in' ? 'Entrada' : 'Saída'
                           }
                           readOnly
                        ></input>
                     </div>
                     <div style={{ alignSelf: 'start' }}>
                        <p className="small-p">Flag:</p>
                        <h3
                           className={
                              movement.movement_flag === 'confirmed'
                                 ? 'flag-confirmed'
                                 : 'flag-excluded'
                           }
                        >
                           {movement.movement_flag === 'confirmed'
                              ? 'CONFIRMADO'
                              : 'EXCLUÍDO'}
                        </h3>
                     </div>
                  </div>
                  <div className="form-container-h">
                     <div className="param-field">
                        <label>ID do movimento:</label>
                        <input type="text" value={movement.id} readOnly></input>
                     </div>

                     <div className="param-field">
                        <label>Usuário:</label>
                        <input
                           type="text"
                           value={movement.user_id}
                           readOnly
                        ></input>
                     </div>

                     <div className="param-field">
                        <label>Data de registro:</label>
                        <input
                           type="text"
                           value={movement.created_at}
                           readOnly
                        ></input>
                     </div>

                     <div className="param-field">
                        <label>PDV:</label>
                        <input
                           type="text"
                           value={movement.pdv_id}
                           readOnly
                        ></input>
                     </div>

                     <div className="param-field">
                        <label>Documento:</label>
                        <input
                           type="text"
                           value={movement.doc_type}
                           readOnly
                        ></input>
                     </div>

                     <div className="param-field">
                        <label>Nº Documento:</label>
                        <input
                           type="text"
                           value={movement.doc_number}
                           readOnly
                        ></input>
                     </div>
                  </div>
               </FormMovement>

               <ProductsList>
                  <thead>
                     <tr>
                        <th>SKU</th>
                        <th>Nome do produto</th>
                        <th>Qtd.</th>
                     </tr>
                  </thead>
                  <tbody>
                     {productDetails.map((product, index) => (
                        <tr key={String(product.id)}>
                           <td>{product.product.prod_sku}</td>
                           <td>{product.product.prod_name}</td>
                           <td>{movement.ItemMovements[index]?.prod_qtd}</td>
                        </tr>
                     ))}
                  </tbody>
               </ProductsList>
               {movement.movement_flag === 'confirmed' ? (
                  <button onClick={handleDeleteMovement}>
                     Excluir movimento
                  </button>
               ) : (
                  ''
               )}
            </>
         )}
      </Container>
   );
}
