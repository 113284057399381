import React, { useEffect, useState } from 'react';

import {
   Container,
   ProductLargeFields,
   ProductSmallFields,
   Title,
} from '../../styles/GlobalStyles';
import { ProductForm } from './styled';
import axios from '../../services/axios';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading';

import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CurrencyInput from '../../components/MinorUtilities/CurrencyInput';
import { parseCurrencyToDecimal } from '../../components/utils/formatCurrency';

export default function NewProduct() {
   // product parameters
   const [id, setId] = useState('');

   // its fetched first
   const [categories, setCategories] = useState([]);
   const [brands, setBrands] = useState([]);

   // parameters list goes here
   const [paramList, setParamList] = useState({});

   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      async function getForeignTables() {
         try {
            setIsLoading(true);
            const categories = await axios.get('/prodcategories');
            const brands = await axios.get('/prodbrands');
            setCategories(categories.data);
            setBrands(brands.data);
            setIsLoading(false);
         } catch (err) {
            const errors = get(err, 'response.data.errors', []);
            errors.map((error) => toast.error(error));
            toast.warn('Redirecionando para a página de login');
            setIsLoading(false);
         }
      }
      getForeignTables();
   }, []);

   const handleChangeCurrency = (name, value) => {
      setParamList((prevParams) => ({
         ...prevParams,
         [name]: value,
      }));
   };

   const handleChangeParams = (e) => {
      const { name, value } = e.target;

      setParamList((prevParams) => ({
         ...prevParams,
         [name]: value, // Atualiza o campo correspondente usando o nome do input
      }));
   };

   const handleConfirmSubmit = async (e) => {
      e.preventDefault();

      if (!paramList) return;

      const response = window.confirm('Confirma o cadastro?');

      if (response === false) {
         toast.info('Cadastro cancelado.');
         return;
      }

      const formattedData = {
         ...paramList,
         prod_status: 'active',
         cost_price: parseCurrencyToDecimal(paramList.cost_price),
      };

      try {
         setIsLoading(true);

         const addItem = await axios.post('/products', formattedData);
         setId(addItem.data.newProduct.id);
         setIsLoading(false);
         console.log(formattedData);

         toast.success('Produto cadastrado com sucesso.');
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         setIsLoading(false);
         errors.map((error) => toast.error(error));
      }
   };

   const navigate = useNavigate();

   useEffect(() => {
      if (id) {
         navigate(`/products/${id}`);
      }
   }, [id, navigate]); // Dispara o efeito quando `id` muda

   const integerRegex = /^[0-9]+$/;

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>CADASTRAR PRODUTO</Title>
         <ProductForm onSubmit={handleConfirmSubmit}>
            <ProductLargeFields>
               <h3>Identificadores</h3>
               <div className="row-fields">
                  <div className="fields">
                     <label>ID:</label>
                     <input type="text" name="id" readOnly value={id}></input>
                  </div>
                  <div className="fields">
                     <label>SKU:</label>
                     <input
                        type="text"
                        name="prod_sku"
                        value={paramList.prod_sku || ''}
                        maxLength={7}
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              handleChangeParams(e);
                           }
                        }}
                     ></input>
                  </div>
                  <div className="fields-large">
                     <label>Nome:</label>
                     <input
                        type="text"
                        name="prod_name"
                        value={paramList.prod_name || ''}
                        onChange={(e) => handleChangeParams(e)}
                     ></input>
                  </div>
               </div>
            </ProductLargeFields>

            <ProductLargeFields>
               <h3>Classificação</h3>
               <div className="row-fields">
                  <div className="fields-medium">
                     <label>Categoria</label>
                     <select
                        value={paramList.category_id}
                        name="category_id"
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Selecione a categoria</option>
                        {categories.map((category) => (
                           <option
                              key={String(category.id)}
                              value={category.id}
                           >
                              {category.name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div className="fields-medium">
                     <label>Marca</label>
                     <select
                        value={paramList.brand_id}
                        name="brand_id"
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Selecione a marca do produto</option>
                        {brands.map((brand) => (
                           <option key={String(brand.id)} value={brand.id}>
                              {brand.name}
                           </option>
                        ))}
                     </select>
                  </div>
               </div>
            </ProductLargeFields>

            <ProductSmallFields>
               <h3>Valores</h3>
               <div className="small-fields">
                  <div className="fields">
                     <label>Estoque mínimo:</label>
                     <input
                        type="text"
                        name="min_stock_qtd"
                        className="prod-measures"
                        value={paramList.min_stock_qtd || ''}
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              handleChangeParams(e);
                           }
                        }}
                     ></input>
                  </div>
                  <div className="fields">
                     <label>Preço de custo:</label>
                     <CurrencyInput
                        value={paramList.cost_price ? paramList.cost_price : 0}
                        disabled={false}
                        onChange={(value) => {
                           handleChangeCurrency('cost_price', value);
                        }}
                     />
                  </div>
               </div>
            </ProductSmallFields>

            <ProductSmallFields>
               <h3>Dimensões da embalagem</h3>
               <div className="small-fields">
                  <div className="fields">
                     <label>Altura</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_height"
                           className="prod-measures"
                           value={paramList.prod_height || ''}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
                  <div className="fields">
                     <label>Largura</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_width"
                           className="prod-measures"
                           value={paramList.prod_width || ''}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
                  <div className="fields">
                     <label>Comprimento</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_length"
                           className="prod-measures"
                           value={paramList.prod_length || ''}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
               </div>
               <div className="small-fields">
                  <div className="fields">
                     <label>Peso bruto</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_weight"
                           className="prod-measures"
                           value={paramList.prod_weight || ''}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>g</p>
                     </div>
                  </div>
               </div>
            </ProductSmallFields>
            <button type="submit">Cadastrar</button>
         </ProductForm>
      </Container>
   );
}
