import React, { useEffect } from 'react';

import { LogoNav, Nav } from './styled';
import UserInfo from './UserInfo';
import MenuItems from './MenuItems';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaSignInAlt } from 'react-icons/fa';

export default function Header() {
   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
   const user = useSelector((state) => state.auth.user);

   // TEST
   // useEffect(() => {
   //    console.log(user);
   // }, [user]);

   return (
      <Nav>
         <div className="nav-content">
            <LogoNav />
            {isLoggedIn ? (
               <>
                  <MenuItems />
                  <UserInfo user={user} />
               </>
            ) : (
               <Link to="/login" title="Login" style={{ marginBottom: '80vh' }}>
                  <FaSignInAlt size={24} />
                  <p>Fazer login</p>
               </Link>
            )}
         </div>
      </Nav>
   );
}
