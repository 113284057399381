import styled from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export const QueryContainer = styled.div`
   margin-top: 24px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .input-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 16px;
      justify-content: space-between;
      margin-bottom: 12px;
   }
   button {
      align-self: flex-end;
   }

   div {
      display: flex;
      flex-direction: column;
   }

   input,
   select {
      height: 36px;
      padding-left: 4px;
   }

   .input-large {
      width: 60%;
      min-width: 320px;
   }
`;
