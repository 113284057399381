import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: ${colors.infoColor};
   text-align: center;
`;

export const HomeContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 24px;
   text-align: center;
   align-items: center;
   height: 100%;
`;
