/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';

import { Container } from '../../styles/GlobalStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/modules/auth/actions';
import { get } from 'lodash';

import { Form, Title, Paragrafo, LoginFlex, LoginImg } from './styled';

import Loading from '../../components/Loading';

export default function Login() {
   const dispatch = useDispatch();
   const location = useLocation();
   const navigate = useNavigate();
   const prevPath = location.state?.prevPath || '/';

   const isLoading = useSelector((state) => state.auth.isLoading);

   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');

   const handleSubmit = (e) => {
      e.preventDefault();
      let formErrors = false;

      if (!isEmail(email)) {
         formErrors = true;
         toast.error('O email precisa ser válido.');
      }

      if (password.length < 6 || password.length > 50) {
         formErrors = true;
         toast.error('Senha inválida.');
      }
      if (formErrors) return;

      dispatch(actions.loginRequest({ email, password, prevPath, navigate }));
   };

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <LoginFlex>
            <LoginImg />
            <Form onSubmit={handleSubmit}>
               <Title> Faça seu login: </Title>
               <div>
                  <label htmlFor="email">Email:</label>
                  <input
                     type="text"
                     id="email"
                     placeholder="Digite seu email de acesso"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
               </div>
               <div>
                  <label htmlFor="password">Senha:</label>
                  <input
                     type="password"
                     id="password"
                     value={password}
                     placeholder="Digite sua senha"
                     onChange={(e) => setPassword(e.target.value)}
                  />
               </div>
               <button type="submit">Login</button>
            </Form>
         </LoginFlex>
      </Container>
   );
}
