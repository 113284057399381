import React, { useEffect, useRef, useState } from 'react';

import {
   Container,
   ProductLargeFields,
   ProductSmallFields,
   Title,
} from '../../styles/GlobalStyles';
import { ProductForm } from './styled';
import { ActionsContainer } from '../../styles/Buttons';
import axios from '../../services/axios';
import { toast } from 'react-toastify';

import Loading from '../../components/Loading';

import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import CurrencyInput from '../../components/MinorUtilities/CurrencyInput';
import {
   parseCurrencyToDecimal,
   formatDecimalToCurrency,
} from '../../components/utils/formatCurrency'; // Usa as funções do utils

export default function ProductEdit() {
   // global parameters
   // const [editOn, setEditOn] = useState(false);
   const formRef = useRef('');

   // product parameters
   const { id } = useParams();

   const [categories, setCategories] = useState([]);
   const [brands, setBrands] = useState([]);

   // parameters list goes here
   const [paramList, setParamList] = useState({});
   const [newParamList, setNewParamList] = useState({});

   const [isLoading, setIsLoading] = useState(false);

   //this state switches the buttons "Editar" and "Salvar"
   const [editOn, setEditOn] = useState(false);

   useEffect(() => {
      const fetchProductData = async () => {
         setIsLoading(true);
         try {
            const product = await axios.get(`/products/${id}`);
            const categories = await axios.get('/prodcategories');
            const brands = await axios.get('/prodbrands');

            const promises = [product.data, categories.data, brands.data];

            Promise.all(promises).then(
               handleAddParams(product.data.product),
               setCategories(promises[1]),
               setBrands(promises[2])
            );
            setIsLoading(false);
         } catch (err) {
            toast.info(err);
            console.log(err);
            setIsLoading(false);
         }
      };
      fetchProductData();
   }, [id]);

   const handleSetInputReadOnly = () => {
      const inputs = formRef.current.querySelectorAll('.editable');
      setEditOn(false);

      inputs.forEach((input) => {
         input.disabled = true;
      });
   };

   const handleEnableInput = (e) => {
      e.preventDefault();
      const inputs = formRef.current.querySelectorAll('.editable');
      setEditOn(true);

      inputs.forEach((input) => {
         input.disabled = false;
      });
   };

   const handleAddParams = (data) => {
      // Aqui, usamos a função utils para formatar o preço de custo
      data.cost_price = formatDecimalToCurrency(parseFloat(data.cost_price)); // Formata para exibição
      setParamList(data);
      setNewParamList(data);
   };

   const handleChangeParams = (e) => {
      const { name, value } = e.target;

      setNewParamList((prevParams) => ({
         ...prevParams,
         [name]: value, // Atualiza o campo correspondente usando o nome do input
      }));
   };

   const handleChangeCurrency = (name, value) => {
      setNewParamList((prevParams) => ({
         ...prevParams,
         [name]: value,
      }));
   };

   const handleSubmitForm = async (e) => {
      e.preventDefault();

      try {
         setIsLoading(true);

         const formattedData = {
            ...newParamList,
            // Aqui, utilizamos o utils para remover a formatação antes de enviar para o backend
            cost_price: parseCurrencyToDecimal(newParamList.cost_price),
         };

         const response = await axios.put(`/products/${id}`, formattedData);
         toast.success(response.data.putMsg);
         setIsLoading(false);
         handleSetInputReadOnly();
      } catch (err) {
         setIsLoading(false);
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => toast.error(error));
      }
   };

   const integerRegex = /^[0-9]+$/;

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>EDITAR PRODUTO</Title>
         <ProductForm onSubmit={handleSubmitForm} ref={formRef}>
            <ProductLargeFields>
               <h3>Identificadores</h3>
               <div className="row-fields">
                  <div className="fields">
                     <label>ID:</label>
                     <input type="text" disabled value={id} name="id"></input>
                  </div>
                  <div className="fields">
                     <label>SKU:</label>
                     <input
                        type="text"
                        name="prod_sku"
                        value={newParamList.prod_sku || ''}
                        maxLength={7}
                        disabled
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              handleChangeParams(e);
                           }
                        }}
                     ></input>
                  </div>
                  <div className="fields-large">
                     <label>Nome:</label>
                     <input
                        className="editable"
                        type="text"
                        name="prod_name"
                        value={newParamList.prod_name || ''}
                        disabled
                        onChange={(e) => handleChangeParams(e)}
                     ></input>
                  </div>
               </div>

               <div className="row-fields">
                  <div className="fields-medium">
                     <label>Categoria</label>
                     <select
                        className="editable"
                        name="category_id"
                        value={newParamList.category_id || ''}
                        disabled
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Selecione a categoria</option>
                        {categories.map((category) => (
                           <option
                              key={String(category.id)}
                              value={category.id}
                           >
                              {category.name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div className="fields-medium">
                     <label>Marca</label>
                     <select
                        className="editable"
                        name="brand_id"
                        value={newParamList.brand_id || ''}
                        disabled
                        onChange={(e) => handleChangeParams(e)}
                     >
                        <option value="">Selecione a marca</option>
                        {brands.map((brand) => (
                           <option key={String(brand.id)} value={brand.id}>
                              {brand.name}
                           </option>
                        ))}
                     </select>
                  </div>
               </div>
            </ProductLargeFields>

            <ProductSmallFields>
               <h3>Valores</h3>
               <div className="small-fields">
                  <div className="fields">
                     <label>Estoque mínimo:</label>
                     <input
                        type="text"
                        name="min_stock_qtd"
                        className="prod-measures editable"
                        value={newParamList.min_stock_qtd || 0}
                        disabled
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              handleChangeParams(e);
                           }
                        }}
                     ></input>
                  </div>
                  <div className="fields">
                     <label>Preço de custo</label>
                     <CurrencyInput
                        value={newParamList.cost_price || '0.00'}
                        onChange={(value) =>
                           handleChangeCurrency('cost_price', value)
                        }
                        disabled={!editOn}
                     />
                  </div>
               </div>
            </ProductSmallFields>

            <ProductSmallFields>
               <h3>Dimensões da embalagem</h3>
               <div className="small-fields">
                  <div className="fields">
                     <label>Altura</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_height"
                           className="prod-measures editable"
                           disabled
                           value={newParamList.prod_height || 0}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
                  <div className="fields">
                     <label>Largura</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_width"
                           className="prod-measures editable"
                           disabled
                           value={newParamList.prod_width || 0}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
                  <div className="fields">
                     <label>Comprimento</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_length"
                           className="prod-measures editable"
                           disabled
                           value={newParamList.prod_length || 0}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>cm</p>
                     </div>
                  </div>
               </div>
               <div className="small-fields">
                  <div className="fields">
                     <label>Peso bruto</label>
                     <div className="input-replica">
                        <input
                           type="text"
                           name="prod_weight"
                           className="prod-measures editable"
                           disabled
                           value={newParamList.prod_weight || 0}
                           onChange={(e) => {
                              if (
                                 integerRegex.test(e.target.value) ||
                                 e.target.value === ''
                              ) {
                                 handleChangeParams(e);
                              }
                           }}
                        ></input>
                        <p>g</p>
                     </div>
                  </div>
               </div>
            </ProductSmallFields>
            {editOn ? (
               <button type="submit" className="submit-btn">
                  Salvar
               </button>
            ) : (
               ''
            )}
         </ProductForm>
         <ActionsContainer>
            <button className="edit-button" onClick={handleEnableInput}>
               Editar
            </button>
            {editOn ? (
               <button
                  className="alert-button"
                  onClick={handleSetInputReadOnly}
               >
                  Cancelar
               </button>
            ) : (
               ''
            )}
         </ActionsContainer>
      </Container>
   );
}
