import styled from 'styled-components';
import * as colors from '../../config/colors';

export const UserPhoto = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: ${colors.primaryDarkColor};
   height: 32px;
   width: 32px;
   border-radius: 100%;
`;

export const UserNavContainer = styled.div`
   height: 80px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-around;
`;
