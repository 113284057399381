import React from 'react';

import { Container } from '../../styles/GlobalStyles';
import { Title, Paragrafo } from './styled';

export default function Unauthorized() {
   return (
      <Container>
         <Title>Você não tem permissão para acessar esta página.</Title>
         <Paragrafo>Suma daqui.</Paragrafo>
         <button type="button"> Min desculpe</button>
      </Container>
   );
}
