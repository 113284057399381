import styled from 'styled-components';

export const CategoryForm = styled.div`
   margin-top: 24px;
   display: flex;
   width: 100%;
   column-gap: 24px;
   align-items: center;

   input {
      width: 100%;
   }
`;
