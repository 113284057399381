import styled from 'styled-components';

export const Title = styled.h1`
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: green;
`;

export const AlunoContainer = styled.div`
   div {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   table {
      width: 100%;
      margin: 24px 0;
      text-align: center;
   }

   th {
      text-align: center;
      height: 48px;
   }
`;
export const ProfilePicture = styled.td`
   img {
      height: 36px;
      width: 36px;
      border-radius: 50%;
   }
`;

export const TdActions = styled.td`
   display: flex;
   justify-content: space-around;
   color: black;
   align-items: center;
`;
