import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: green;
`;

export const Pagination = styled.div`
   display: flex;
   justify-content: center;
   margin: 20px 0;
   width: 420px;

   button {
      background: #f0f0f0;
      border: 1px solid #ddd;
      border-radius: 3px;
      color: #333;
      cursor: pointer;
      padding: 10px 15px;
      margin: 0 5px;
      font-size: 16px;

      &:hover:not(:disabled) {
         background: ${colors.primaryColor};
      }

      &.active {
         background: #007bff;
         color: #fff;
         border: 1px solid #007bff;
      }

      &:disabled {
         cursor: not-allowed;
         opacity: 0.5;
      }
   }
`;
