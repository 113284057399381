import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   margin-bottom: 24px;
`;

export const Paragrafo = styled.p`
   color: ${colors.primaryDarkColor};
   margin-bottom: 24px;
`;

export const FormQuery = styled.form`
   display: flex;
   column-gap: 16px;
   align-items: center;
   input {
      height: 36px;
   }

   button {
      height: 36px;
   }
`;

export const ProductQuery = styled.table`
   margin-top: 24px;
   text-align: left;
   border-collapse: collapse;
   width: 100%;

   th,
   td {
      border: 1px solid #ddd;
   }

   th {
      text-align: center;
      padding: 8px 16px 8px 8px;
   }

   td {
      padding: 4px 16px 4px 8px;
   }

   .prod-name {
      text-align: left;
      width: 500px; /* Define a largura padrão */
      max-width: 500px; /* Define a largura máxima */
      overflow: hidden;
      text-overflow: ellipsis; /* Adiciona reticências para texto que ultrapassa a largura máxima */
      white-space: nowrap; /* Impede que o texto quebre em várias linhas */
   }
`;

export const ProductData = styled.div`
   display: flex;
   text-align: left;
   column-gap: 24px;
   margin-top: 24px;
   color: white;

   div {
      background-color: ${colors.primaryDarkColor};
      padding: 16px;
      border-radius: 4px;
   }
   div > h3 {
      margin-top: 16px;
   }
`;
