import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from '../../components/Loading';

import axios from '../../services/axios';

import { Container } from '../../styles/GlobalStyles';
import {
   Title,
   Pagination,
   QueryContainer,
   MovementsContainer,
} from './styled';
import { toast } from 'react-toastify';

export default function Movements() {
   const [pdvs, setPdvs] = useState([]);
   const [movements, setMovements] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);

   // query parameters
   const [id, setId] = useState('');
   const [pdvId, setPdvId] = useState('');
   const [moveType, setMoveType] = useState('');
   const [docNumber, setDocNumber] = useState('');
   const [movementFlag, setMovementFlag] = useState('');

   const itemsPerPage = 15;

   useEffect(() => {
      async function getData() {
         try {
            setIsLoading(true);
            //const response = await axios.get('/movement-search');
            const pdvResponse = await axios.get('/pdvs');
            setPdvs(pdvResponse.data);
            setIsLoading(false);
         } catch (err) {
            const [error] = err.response.data.errors;
            toast.error(error);
            toast.warn('Redirecionando para a página de login');
            console.log(error);
            setIsLoading(false);
         }
      }
      getData();
   }, []);

   const handleQueryParams = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const query = {
         id: id,
         pdv_id: pdvId,
         move_type: moveType,
         doc_number: docNumber,
         movement_flag: movementFlag,
      };
      const response = await axios.post('/movement-search', query);
      setMovements(response.data);
      setIsLoading(false);
   };

   // Calcula o índice do primeiro e do último item a ser exibido na página atual
   const indexOfLastMovement = currentPage * itemsPerPage;
   const indexOfFirstMovement = indexOfLastMovement - itemsPerPage;

   // Cria uma lista de produtos para exibir na página atual
   const currentMovements = movements.slice(
      indexOfFirstMovement,
      indexOfLastMovement
   );

   // Calcula o número total de páginas
   const totalPages = Math.ceil(movements.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   // Lógica para renderizar somente 15 itens por vez na paginação
   const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5;
      const halfRange = Math.floor(maxPageNumbersToShow / 2);

      let startPage = Math.max(1, currentPage - halfRange);
      let endPage = Math.min(totalPages, currentPage + halfRange);

      if (currentPage <= halfRange) {
         endPage = Math.min(totalPages, maxPageNumbersToShow);
      }

      if (currentPage + halfRange >= totalPages) {
         startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
         pageNumbers.push(
            <button
               key={i}
               onClick={() => handlePageChange(i)}
               className={currentPage === i ? 'active' : ''}
            >
               {i}
            </button>
         );
      }

      return pageNumbers;
   };

   const navigate = useNavigate();

   const handleRowClick = (id) => {
      navigate(`/movements/${id}`);
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>Movimentos</Title>
         <p>
            Faça uma consulta nos movimentos registrados para obter mais
            informações.
         </p>
         <QueryContainer>
            <form onSubmit={handleQueryParams}>
               <div className="input-fields">
                  <div>
                     <label>ID:</label>
                     <input
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        type="text"
                     ></input>
                  </div>
                  <div>
                     <label>Selecione o PDV:</label>
                     <select
                        value={pdvId}
                        onChange={(e) => setPdvId(e.target.value)}
                     >
                        <option value="">Selecione um PDV</option>
                        {pdvs.map((pdv) => (
                           <option key={String(pdv.id)} value={pdv.id}>
                              {pdv.pdv_name}
                           </option>
                        ))}
                     </select>
                  </div>
                  <div>
                     <label>Tipo de movimento:</label>
                     <select
                        value={moveType}
                        onChange={(e) => setMoveType(e.target.value)}
                     >
                        <option value="">Selecione um tipo</option>
                        <option value="in">Entrada</option>
                        <option value="out">Saída</option>
                     </select>
                  </div>
                  <div>
                     <label>Nº documento:</label>
                     <input
                        type="text"
                        value={docNumber}
                        onChange={(e) => setDocNumber(e.target.value)}
                     ></input>
                  </div>
                  <div>
                     <label>Status do movimento:</label>
                     <select
                        value={movementFlag}
                        onChange={(e) => setMovementFlag(e.target.value)}
                     >
                        <option value="">Selecione o status</option>
                        <option value="confirmed">Confirmado</option>
                        <option value="deleted">Excluído</option>
                     </select>
                  </div>
               </div>
               <button type="submit">Pesquisar</button>
            </form>
         </QueryContainer>
         <MovementsContainer>
            <table>
               <thead>
                  <tr>
                     <th>ID</th>
                     <th>PDV</th>
                     <th>Tipo</th>
                     <th>Documento</th>
                     <th>Nº Documento</th>
                     <th>Data e hora</th>
                     <th>Situação</th>
                  </tr>
               </thead>
               <tbody>
                  {currentMovements.map((movement) => (
                     <tr
                        key={String(movement.id)}
                        onDoubleClick={() => handleRowClick(movement.id)}
                     >
                        <td>{movement.id}</td>
                        <td>{movement.pdv_id}</td>
                        <td>
                           {movement.move_type === 'in' ? 'ENTRADA' : 'SAÍDA'}
                        </td>
                        <td>{movement.doc_type}</td>
                        <td>{movement.doc_number}</td>
                        <td>{movement.created_at}</td>
                        <td
                           className={
                              movement.movement_flag === 'confirmed'
                                 ? 'confirmed-movement'
                                 : 'excluded-movement'
                           }
                        >
                           {movement.movement_flag === 'confirmed'
                              ? 'CONFIRMADO'
                              : 'EXCLUÍDO'}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </MovementsContainer>
         <Pagination>
            <button
               onClick={() => handlePageChange(currentPage - 1)}
               disabled={currentPage === 1}
            >
               Anterior
            </button>
            {renderPageNumbers()}
            <button
               onClick={() => handlePageChange(currentPage + 1)}
               disabled={currentPage === totalPages}
            >
               Próximo
            </button>
         </Pagination>
         {/* Adicionado o parágrafo abaixo para exibir o total de páginas */}
         <p>Total de páginas: {totalPages}</p>
         <p>Total de movimentos: {movements.length}</p>
      </Container>
   );
}
