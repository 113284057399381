export const formatDecimalToCurrency = (value) => {
   return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
   });
};

export const parseCurrencyToDecimal = (currencyString) => {
   if (!currencyString) return 0;

   // Remove todos os caracteres que não sejam dígitos ou vírgula.
   const cleanedString = currencyString.replace(/[^\d,-]/g, '');

   // Substitui a vírgula por um ponto para conversão correta para float.
   const decimalValue = cleanedString.replace(',', '.');

   // Converte para decimal.
   return parseFloat(decimalValue) || 0; // Retorna 0 se o parse falhar
};
