import React, { useState } from 'react';

import { Container } from '../../styles/GlobalStyles';
import {
   ProductQuery,
   Title,
   ProductData,
   Paragrafo,
   FormQuery,
} from './styled';
import axios from '../../services/axios';
import { get } from 'lodash';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';

export default function ProdQuery() {
   const [sku, setSku] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [product, setProduct] = useState([]);
   const [stock, setStock] = useState([]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         setIsLoading(true);

         if (!sku) {
            toast.error('Informe o SKU.');
            setIsLoading(false);
            return;
         }

         const response = await axios.get(`stock/${sku}`);
         setProduct(response.data.prod);
         setStock(response.data.stock);

         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => toast.error(error));
         setIsLoading(false);
      }
   };

   const integerRegex = /^[0-9]+$/;

   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>Pesquisa de estoque disponível</Title>
         <Paragrafo>
            Nesta tela você pode consultar a quantidade de estoque disponível de
            um produto, onde serão exibidos os resultados dos pontos de venda de
            Goiânia e região (Anápolis não incluso).
         </Paragrafo>
         <FormQuery onSubmit={handleSubmit}>
            <label>Código do produto (SKU):</label>
            <input
               type="text"
               value={sku}
               maxLength={7}
               onChange={(e) => {
                  if (
                     integerRegex.test(e.target.value) ||
                     e.target.value === ''
                  ) {
                     setSku(e.target.value);
                  }
               }}
            ></input>
            <button type="submit">Pesquisar</button>
         </FormQuery>
         {product.id && (
            <>
               <ProductData>
                  <div>
                     <p>ID do produto:</p>
                     <h3>{product.id}</h3>
                  </div>

                  <div>
                     <p>SKU do produto:</p>
                     <h3>{product.prod_sku}</h3>
                  </div>

                  <div>
                     <p>Nome do produto:</p>
                     <h3>{product.prod_name}</h3>
                  </div>
               </ProductData>
               <ProductQuery>
                  <thead>
                     <tr>
                        <th style={{ width: '80%' }}>Ponto de venda</th>
                        <th>Quantidade disp.</th>
                     </tr>
                  </thead>
                  <tbody>
                     {stock.map((data) => (
                        <tr key={String(data.id)}>
                           <td>{data.pdv_name}</td>
                           <td style={{ textAlign: 'center' }}>
                              {data.stock_qtd}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </ProductQuery>
            </>
         )}
      </Container>
   );
}
