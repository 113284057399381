export const primaryColor = '#4098ef';
export const primaryColorLight = '#bbd7f2';
export const primaryDarkColor = '#373737';
export const lightGrayColor = '#bfbfbf';
export const lightBgText = '#e5e5e5';

export const sucessColor = '#0197F6';
export const infoColor = '#d1a02e';
export const infoColorHover = '#e3ae32';
export const errorColor = '#ab2020';
export const errorColorHover = '#bf2a2a';
export const warningColor = '#F2AF29';
