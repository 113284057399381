import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import Routing from './routes';
import GlobalStyles from './styles/GlobalStyles';
import Menu from './components/Menu';

function App() {
   return (
      <Provider store={store}>
         <PersistGate persistor={persistor}>
            <BrowserRouter>
               <Menu />
               <Routing />
               <GlobalStyles />
               <ToastContainer autoClose={3000} className="toast-container" />
            </BrowserRouter>
         </PersistGate>
      </Provider>
   );
}

export default App;
