import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   text-align: left;
   width: 100%;
   margin-bottom: 24px;
`;

export const Paragrafo = styled.p`
   color: ${colors.infoColor};
   text-align: center;
`;

export const HomeContainer = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 24px;
   padding: 24px 0;
`;

export const ProductForm = styled.form`
   width: 100%;
   display: flex;
   flex-direction: column;
   row-gap: 24px;

   input {
      height: 36px;
      border-radius: 4px;
      border: 1px solid #aaa;
   }

   .form-h {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      div {
         display: flex;
         flex-direction: column;
      }
   }

   .prod-measures {
      width: 80px;
   }

   .currency-field {
      width: 160px;
   }

   .prod-input {
      width: 60%;
   }

   .prod-description {
      width: 100%;
      height: 80px;
      max-width: 100%;
      min-width: 100%;
   }

   button {
      margin-top: 24px;
      width: 80px;
   }

   input:read-only {
      background-color: #ddd;
   }
`;
