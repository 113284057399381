import React, { useEffect, useState } from 'react';
//import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { get } from 'lodash';
import { FaUserCircle, FaEdit, FaWindowClose } from 'react-icons/fa';

import { Container } from '../../styles/GlobalStyles';
import { Title, AlunoContainer, ProfilePicture } from './styled';

import axios from '../../services/axios';

import Loading from '../../components/Loading';
import { toast } from 'react-toastify';

export default function Alunos() {
   // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

   const [alunos, setAlunos] = useState([]);
   const [totalWeight, setTotalWeight] = useState(0);

   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      async function getData() {
         setIsLoading(true);
         const response = await axios.get('/alunos');
         setAlunos(response.data);
         const weightSum = response.data.reduce(
            (acc, aluno) => acc + aluno.peso,
            0
         );
         setTotalWeight(weightSum);
         setIsLoading(false);
      }
      getData();
   }, [alunos.peso]);

   const handleDelete = async (e, id, index) => {
      e.preventDefault();
      const deleteConfirm = window.confirm('Tem certeza que deseja apagar?');

      if (deleteConfirm) {
         try {
            setIsLoading(true);
            await axios.delete(`/alunos/${id}`);
            const newAlunos = [...alunos];
            newAlunos.splice(index, 1);
            setAlunos(newAlunos);
            setIsLoading(false);

            const weightSum = newAlunos.reduce(
               (acc, aluno) => acc + aluno.peso,
               0
            );
            setTotalWeight(weightSum);

            toast.success(`Aluno ID ${id} foi excluído com sucesso.`);
         } catch (err) {
            const errors = get(err, 'response.data.errors', []);
            errors.map((error) => toast.error(error));
            setIsLoading(false);
         }
      } else {
         toast.info('Você cancelou a exclusão.');
         setIsLoading(false);
      }
   };
   return (
      <Container>
         <Loading isLoading={isLoading} />
         <Title>
            Essa é a tela de <small>Alunos</small>
         </Title>
         <AlunoContainer>
            <table>
               <thead>
                  <tr>
                     <th>ID</th>
                     <th>Foto</th>
                     <th>Nome</th>
                     <th>Sobrenome</th>
                     <th>E-mail</th>
                     <th>Idade</th>
                     <th>Altura</th>
                     <th>Peso</th>
                     <th>Editar</th>
                     <th>Apagar</th>
                  </tr>
               </thead>
               <tbody>
                  {alunos.map((aluno, index) => (
                     <tr key={String(aluno.id)}>
                        <td>{aluno.id}</td>
                        <ProfilePicture>
                           {get(aluno, 'Fotos[0].url', false) ? (
                              <img src={aluno.Fotos[0].url}></img>
                           ) : (
                              <FaUserCircle size={36} />
                           )}
                        </ProfilePicture>
                        <td>{aluno.nome}</td>
                        <td>{aluno.sobrenome}</td>
                        <td>{aluno.email}</td>
                        <td>{aluno.idade} anos</td>
                        <td>{aluno.altura} m</td>
                        <td>{aluno.peso} Kg</td>
                        <td>
                           <Link to={`${aluno.id}/edit`}>
                              <FaEdit size={16} />
                           </Link>
                        </td>
                        <td>
                           <Link
                              onClick={(e) => handleDelete(e, aluno.id, index)}
                              to={`/alunos/${aluno.id}`}
                           >
                              <FaWindowClose size={16} />
                           </Link>
                        </td>
                     </tr>
                  ))}
               </tbody>
               <tfoot>
                  <tr>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th>Peso total:</th>
                     <th></th>
                     <th></th>
                  </tr>
                  <tr>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td>{totalWeight.toFixed(2)} Kg</td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr></tr>
               </tfoot>
            </table>
         </AlunoContainer>
      </Container>
   );
}
