import styled from 'styled-components';

export const Title = styled.h1`
   text-align: center;
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: gray;
   text-align: center;
`;

export const Form = styled.form`
   display: flex;
   flex-direction: column;
   row-gap: 24px;
   margin: 24px 0;
   align-items: center;
   width: 50%;

   div {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      column-gap: 16px;
      justify-content: space-between;
      align-items: center;
   }
   input {
      height: 32px;
      width: 100%;
      border: 1px solid #aaa;
      border-radius: 4px;
      padding-left: 8px;
   }

   label {
      width: 100%;
   }

   h3 {
      text-align: center;
   }

   button {
      height: 32px;
      margin-top: 16px;
      width: 40%;
      padding: 8px 0;
      display: block;
      align-self: flex-start;
      margin: 0;
   }
`;
