import React from 'react';
import PropTypes from 'prop-types';
import loading from '../../assets/loading.svg';

import { Container } from './styled';

export default function Loading({ isLoading = false }) {
   if (!isLoading) return <></>;
   return (
      <Container>
         <div />
         <span>
            <img src={loading} />
         </span>
      </Container>
   );
}

Loading.propTypes = {
   isLoading: PropTypes.bool,
};
