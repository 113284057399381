import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Container } from '../../styles/GlobalStyles';

export default function Page404() {
   return (
      <Container>
         <FaInfoCircle />
         <h3>Essa página não existe.</h3>
      </Container>
   );
}
