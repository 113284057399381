import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container } from '../../styles/GlobalStyles';
import {
   Title,
   Paragrafo,
   FormMovement,
   ProductForm,
   ProductsList,
   MustHave,
} from './styled';
import { FaMinusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { get } from 'lodash';
import axios from '../../services/axios';
import { Link } from 'react-router-dom';

export default function MovementIn() {
   const userId = useSelector((state) => state.auth.user.id);

   // Declarations for paramList
   const [pdv, setPdv] = useState('');
   const [docType, setDocType] = useState('');
   const [docNumber, setDocNumber] = useState('');
   const [description, setDescription] = useState('');

   // Declarations for productList
   const [sku, setSku] = useState('');
   const [prodName, setProdName] = useState('');
   const [quantity, setQuantity] = useState('');
   const [prodId, setProdId] = useState('');

   const [productList, setProductList] = useState([]);
   const [paramList, setParamList] = useState({});

   const [isLoading, setIsLoading] = useState(false);

   const quantityInputRef = useRef(null);
   const skuInputRef = useRef(null);

   useEffect(() => {
      handleAddParam();
   }, [docNumber, docType, pdv, description]);

   const handleQueryProduct = async (e) => {
      e.preventDefault();
      try {
         setIsLoading(true);

         const response = await axios.get(`products/search/${sku}`);
         setProdName(response.data.prod_name);
         setProdId(response.data.id);

         // Move focus to quantity input if product search is successful
         if (response.status === 200 && response.data.prod_name) {
            quantityInputRef.current.focus();
         }
         setIsLoading(false);
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => {
            toast.error(error);
         });
         setProdName('');
         setIsLoading(false);
      }
   };

   const handleAddParam = () => {
      const params = {
         pdv_id: pdv,
         move_type: 'in',
         doc_type: docType,
         doc_number: docNumber,
         movement_description: description,
         user_id: userId,
      };
      setParamList(params);
   };

   const handleAddItem = (e) => {
      e.preventDefault();
      if (sku && prodName && quantity) {
         const newProduct = {
            prod_id: parseInt(prodId),
            prod_qtd: parseInt(quantity),
            move_type: 'in',
            pdv_id: parseInt(pdv),
            sku,
            prodName,
         };

         setProductList([...productList, newProduct]);
         setSku('');
         setProdName('');
         setQuantity('');
         skuInputRef.current.focus();
      } else {
         toast.error('Por favor, preencha todos os campos.');
      }
   };

   const handleKeyPress = (e) => {
      if (e.key === 'Enter' || e.key === 'Tab') {
         handleQueryProduct(e);
      }
   };

   const handleAddWithKeyPress = (e) => {
      if (e.key === 'Enter') {
         handleAddItem(e);
      }
   };

   const handleConfirmRegister = (e) => {
      e.preventDefault();

      const registerConfirm = window.confirm('Salvar entrada de mercadorias?');

      if (registerConfirm) {
         handleAddParam();
         handleSubmitEntry();
      } else {
         toast.success('Falha!');
      }
   };

   const handleSubmitEntry = async () => {
      if (!paramList.pdv_id) {
         toast.error('Preencha todos os parâmetros.');
         return;
      }

      try {
         setIsLoading(true);
         const movementData = {
            movement: paramList,
            items: productList,
         };
         // console.log(movementData); // dev test
         await axios.post('/movements', movementData);
         // console.log(paramList, productList); // dev test
         toast.success('Movimento registrado com sucesso.');
         setIsLoading(false);
         handleClearFields();
      } catch (err) {
         const errors = get(err, 'response.data.errors', []);
         errors.map((error) => toast.error(error));
         setIsLoading(false);
      }
   };

   const handleClearFields = () => {
      setPdv('');
      setDocType('');
      setDocNumber('');
      setProductList([]);
      setParamList({});
      setDescription('');
   };

   const handleDeleteItem = (index) => {
      const newProductList = productList.filter((_, i) => i !== index);
      setProductList(newProductList);
   };

   const integerRegex = /^[0-9]+$/;

   return (
      <Container>
         <Loading isLoading={isLoading} />

         <Title>Entrada de produtos</Title>

         <Paragrafo>
            Nesta página você irá registrar a <strong>entrada</strong> de
            produtos
         </Paragrafo>

         {/* Este bloco é responsável por adicionar os parâmeros do movimento na lista */}
         <FormMovement>
            <h3>Parâmetros</h3>

            <div className="form-container-h">
               <div className="param-field">
                  <label>
                     PDV:<MustHave>*</MustHave>
                  </label>
                  <select value={pdv} onChange={(e) => setPdv(e.target.value)}>
                     <option value="" disabled>
                        Selecione o ponto de venda
                     </option>
                     <option value="1">LOJA CENTRAL</option>
                     <option value="2">LOJA LEVE</option>
                     <option value="3">LOJA MANGALO</option>
                     <option value="4">LOJA AV. RIO VERDE</option>
                     <option value="5">LOJA AV. INDEPENDÊNCIA</option>
                     <option value="6">STAND ATACADÃO</option>
                     <option value="7">STAND PORTAL NORTE SHOPPING</option>
                  </select>
               </div>

               <div className="param-field">
                  <label>
                     Documento de entrada:<MustHave>*</MustHave>
                  </label>
                  <select
                     value={docType}
                     onChange={(e) => setDocType(e.target.value)}
                  >
                     <option value="" disabled>
                        Selecione o tipo de documento
                     </option>
                     <option value="NOTA FISCAL">NOTA FISCAL</option>
                     <option value="PEDIDO">PEDIDO</option>
                  </select>
               </div>

               <div className="param-field">
                  <label>
                     Nº Documento:<MustHave>*</MustHave>
                  </label>
                  <input
                     type="text"
                     value={docNumber}
                     onChange={(e) => {
                        if (
                           integerRegex.test(e.target.value) ||
                           e.target.value === ''
                        ) {
                           setDocNumber(e.target.value);
                        }
                     }}
                  ></input>
               </div>
            </div>
            <div className="param-field">
               <h3>Descrição:</h3>
               <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value.toUpperCase())}
               ></textarea>
            </div>

            {/* Este bloco é responsável por adicionar os itens(produtos) na lista */}
            <ProductForm>
               <h3>Produtos</h3>
               <div className="form-container-h">
                  <div className="sku">
                     <label>SKU</label>
                     <input
                        type="text"
                        value={sku}
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              setSku(e.target.value);
                           }
                        }}
                        ref={skuInputRef}
                        onKeyDown={handleKeyPress}
                     ></input>
                  </div>
                  <button type="button" onClick={handleQueryProduct}>
                     Pesquisar
                  </button>
                  <div className="prod-name">
                     <label>Nome produto</label>
                     <input type="text" value={prodName} disabled></input>
                  </div>
                  <div className="prod-qtd">
                     <label>Quantidade</label>
                     <input
                        type="text"
                        value={quantity}
                        onChange={(e) => {
                           if (
                              integerRegex.test(e.target.value) ||
                              e.target.value === ''
                           ) {
                              setQuantity(e.target.value);
                           }
                        }}
                        ref={quantityInputRef}
                        onKeyDown={handleAddWithKeyPress}
                     ></input>
                  </div>
                  <button type="button" onClick={handleAddItem}>
                     Adicionar
                  </button>
               </div>
            </ProductForm>
         </FormMovement>
         {productList.length === 0 ? (
            ''
         ) : (
            <ProductsList>
               <thead>
                  <tr>
                     <th>SKU</th>
                     <th>Nome do produto</th>
                     <th>Qtd.</th>
                     <th>Ações</th>
                  </tr>
               </thead>
               <tbody>
                  {productList.map((product, index) => (
                     <tr key={index}>
                        <td style={{ textAlign: 'center' }}>{product.sku}</td>
                        <td>{product.prodName}</td>
                        <td style={{ textAlign: 'center' }}>
                           {product.prod_qtd}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                           <Link
                              onClick={() => {
                                 const confirm = window.confirm('Tem certeza?');

                                 if (confirm) {
                                    handleDeleteItem(index);
                                 }
                              }}
                              title={
                                 product.prod_qtd > 1
                                    ? 'Remover items'
                                    : 'Remover item'
                              }
                           >
                              <FaMinusCircle size={18} className="delete-btn" />
                           </Link>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </ProductsList>
         )}
         {productList.length === 0 ? (
            <button disabled={true}>Salvar Entrada</button>
         ) : (
            <button onClick={(handleAddParam, handleConfirmRegister)}>
               Salvar Entrada
            </button>
         )}
      </Container>
   );
}
