import styled, { createGlobalStyle } from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
   * {
      margin: 0;
      padding: 0;
      outline: none;
      box-sizing: border-box;
   }

   body {
      font-family: 'Noto Sans', sans-serif;
      background: ${colors.primaryDarkColor};
      color: ${colors.primaryDarkColor};
      padding: 0 0 0 12vw;
      min-width: 1180px;
   }

   #root {
      display: flex;
   }

   input,
   select {
      height: 36px;
      padding-left: 4px;
   }
   /* html, body, #root {
      height: 100%;
   } */

   .edit-button {
      background-color: ${colors.infoColor};
   }

   .edit-button:hover {
      background-color: ${colors.infoColorHover};
   }

   .alert-button {
      background-color: ${colors.errorColor};
   }

   .alert-button:hover {
      background-color: ${colors.errorColorHover};
   }

   button {
      cursor: pointer;
      background-color: ${colors.primaryColor};
      border: none;
      color: white;
      padding: 8px;
      border-radius: 4px;
      font-weight: bold;
   }

   textarea {
      resize: none;
      text-transform: uppercase;
   }

   button:hover {
      background-color: ${colors.sucessColor}
   }

   button:disabled {
      background-color: ${colors.primaryDarkColor};
      color: #fff;
      cursor: not-allowed;
   }

   a {
      text-decoration: none;
      color: ${colors.primaryDarkColor};
      font-weight: bold;
      transition: 0.1s;
   }

   a:hover {
      color: ${colors.sucessColor};
   }

   ul {
      list-style: none;
   }
`;

export const Container = styled.section`
   height: auto;
   width: 60vw;
   min-width: 680px;
   background: #fff;
   margin: 30px auto;
   padding: 32px;
   border-radius: 4px;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const TableHeader = styled.div`
   margin-top: 24px;
   background-color: ${colors.primaryColor};
   color: white;
   text-align: center;
   padding: 8px 0;
   border-radius: 8px 8px 0 0;
   width: 100%;
`;

export const TableFooter = styled.div`
   background-color: ${colors.primaryColor};
   color: white;
   text-align: center;
   padding: 8px 0;
   border-radius: 0 0 8px 8px;
   width: 100%;
`;

export const TableData = styled.div`
   width: 100%;
   overflow-x: auto;
   border: 1px solid ${colors.primaryColor};

   table {
      width: 100%;
      border-spacing: 0;
   }

   tr {
      height: 12px;
   }

   th {
      border-collapse: collapse;
      background-color: ${colors.primaryDarkColor};
      color: #fff;
      padding: 8px 4px;
   }

   td {
      border-collapse: collapse;
      border: 1px solid ${colors.lightGrayColor};
      padding: 8px 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 360px;
   }

   tr:hover {
      color: ${colors.primaryColor};
   }

   .th-major-w {
      width: 360px;
   }
   .td-small-w {
      text-align: center;
      width: 80px;
   }
   .th-extralarge-w {
      width: 480px;
   }
   .td-medium-w {
      width: 240px;
   }
`;

export const TrEnabledAction = styled.tr`
   &:hover {
      background-color: ${colors.primaryColorLight};
      cursor: pointer;

      td {
         color: ${colors.primaryDarkColor};
      }
   }
`;

export const TrDisabledAction = styled.tr`
   &:hover {
      background-color: ${colors.lightBgText};
      cursor: pointer;

      td {
         color: ${colors.primaryDarkColor};
         cursor: text;
      }
   }
`;

// Estilos para produtos
export const ProductLargeFields = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 24px;
   border: 1px solid ${colors.lightBgText};
   border-radius: 8px;
   width: 100%;
   padding: 24px;

   input,
   select {
      font-size: 1.1em;
   }

   label {
      font-size: 0.8em;
      margin-bottom: 4px;
   }

   &:focus-within {
      border: 1px solid ${colors.primaryColor};
   }

   input:focus-within:not(:read-only) {
      border: 2px solid ${colors.primaryColor};
   }

   select {
      border: 1px solid ${colors.lightGrayColor};
      border-radius: 4px;
   }

   .row-fields {
      display: flex;
      width: 100%;
      column-gap: 24px;
      justify-content: space-between;
      align-items: center;
   }

   .fields {
      display: flex;
      flex-direction: column;
      width: 20%;
   }
   .fields-large {
      display: flex;
      flex-direction: column;
      width: 60%;
   }
   .fields-medium {
      display: flex;
      flex-direction: column;
      width: 50%;
   }
`;

export const ProductSmallFields = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 24px;
   border: 1px solid ${colors.lightBgText};
   border-radius: 8px;
   width: 100%;
   padding: 24px;

   &:focus-within {
      border: 1px solid ${colors.primaryColor};
   }

   h3 {
      text-align: left;
   }

   input {
      width: 80px;
      text-align: right;
      padding: 8px;
      font-size: 1.1em;
   }

   label {
      font-size: 0.8em;
      margin-bottom: 4px;
   }

   div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 24px;
   }

   .input-replica {
      border: 1px solid ${colors.lightGrayColor};
      width: 120px;
      height: 36px;
      border-radius: 4px;
      align-items: center;
      justify-content: space-around;
      column-gap: 0;

      p {
         color: ${colors.lightGrayColor};
      }

      input {
         background-color: #fff;
         border: none;
         width: 70%;
         height: 100%;
      }
   }

   .input-replica:focus-within {
      border: 2px solid ${colors.primaryColor};
   }

   .small-fields {
      display: flex;
      justify-content: start;
      flex-direction: row;
   }

   .fields {
      display: flex;
      flex-direction: column;
   }
`;

// TEXT STYLES

export const Title = styled.h1`
   text-align: left;
   width: 100%;
   margin-bottom: 24px;
`;
