import styled from 'styled-components';
import * as colors from '../../config/colors';

export const Title = styled.h1`
   small {
      color: red;
   }
`;

export const Paragrafo = styled.p`
   color: green;
`;

export const QueryContainer = styled.div`
   margin-top: 24px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .input-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 16px;
      justify-content: space-between;
      margin-bottom: 12px;
   }
   button {
      align-self: flex-end;
   }

   div {
      display: flex;
      flex-direction: column;
   }

   input,
   select {
      height: 36px;
      padding-left: 4px;
   }
`;

export const MovementsContainer = styled.div`
   width: 100%;
   div {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   table {
      width: 100%;
      margin: 24px 0;
      border-collapse: collapse; /* Certifica-se de que a propriedade border-spacing funcione */
   }

   td {
      padding: 4px 16px 4px 8px;
      text-align: center;
   }

   tr {
      cursor: pointer;
   }

   th {
      cursor: auto;
   }

   tr:hover {
      color: ${colors.primaryColor};
   }

   td,
   th {
      border: 1px solid #ddd;
      padding: 4px;
   }

   th {
      background-color: ${colors.primaryDarkColor};
      color: #fff;
      text-align: center;
      height: 48px;
   }

   td.movement-name {
      text-align: left;
      width: 500px; /* Define a largura padrão */
      max-width: 500px; /* Define a largura máxima */
      overflow: hidden;
      text-overflow: ellipsis; /* Adiciona reticências para texto que ultrapassa a largura máxima */
      white-space: nowrap; /* Impede que o texto quebre em várias linhas */
   }

   .excluded-movement {
      font-weight: bold;
      color: ${colors.lightBgText};
   }

   .confirmed-movement {
      font-weight: bold;
      color: ${colors.sucessColor};
   }
`;
export const Pagination = styled.div`
   display: flex;
   justify-content: center;
   margin: 20px 0;
   width: 420px;

   button {
      background: #f0f0f0;
      border: 1px solid #ddd;
      border-radius: 3px;
      color: #333;
      cursor: pointer;
      padding: 10px 15px;
      margin: 0 5px;
      font-size: 16px;

      &:hover {
         background: #ddd;
      }

      &.active {
         background: #007bff;
         color: #fff;
         border: 1px solid #007bff;
      }

      &:disabled {
         cursor: not-allowed;
         opacity: 0.5;
         background-color: ${colors.lightBgText};
      }
   }
`;
