/* eslint-disable no-unused-vars */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
export default function PrivateRoute({ element: Element, isClosed, ...rest }) {
   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

   const location = useLocation();

   if (isClosed && !isLoggedIn) {
      const prevPath = location.pathname;
      console.log('Forbidden: closed route.', prevPath); // dev test

      return <Navigate to="/login" replace state={{ prevPath }} />;
   }

   return <Element {...rest} />;
}

PrivateRoute.propTypes = {
   element: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
   isClosed: PropTypes.bool,
};
